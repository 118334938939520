import React from "react";
import { RouteObject, Navigate, useRoutes } from "react-router-dom";
import App from "../App";
import About from "../pages/About";
import BankTransfer from "../pages/BankTransfer";
import Contact from "../pages/Contact";
import Give from "../pages/Give";
import GMTV from "../pages/GMTV";
import Homepage from "../pages/Homepage";
import Login from "../pages/Login";
import MediaCenter from "../pages/MediaCenter";
import Mentorship from "../pages/Mentorship";
import Ministries from "../pages/Ministries";
import { PrivateResource } from "../pages/PrivateResource";
import PrivateStream from "../pages/PrivateStream";
import Profile from "../pages/Profile";
import PublicResource from "../pages/PublicResource";
import PublicStream from "../pages/PublicStream";
import Register from "../pages/Register";
import VideoPlayer from "../pages/VideoPlayer";
import VideoPlaylist from "../pages/VideoPlaylist";

const routes: RouteObject[] = [
  {
    path: "",
    element: <App />,
    children: [
      { path: "/", element: <Homepage /> },
      { path: "/about", element: <About /> },
      { path: "/mentorship", element: <Mentorship /> },
      { path: "/give", element: <Give /> },
      { path: "/media", element: <MediaCenter /> },
      { path: "/contact", element: <Contact /> },
      { path: "/vod", element: <VideoPlaylist /> },
      { path: "/player", element: <VideoPlayer /> },
      { path: "/ministries", element: <Ministries /> },
      { path: "/bank-transfer", element: <BankTransfer /> },
      { path: "/gmtv", element: <GMTV /> },
      { path: "/register", element: <Register /> },
      { path: "/dashboard", element: <Profile /> },
      { path: "/login", element: <Login /> },
      { path: "/public-stream", element: <PublicStream /> },
      { path: "/private-stream", element: <PrivateStream /> },
      { path: "/public-resource", element: <PublicResource /> },
      { path: "/private-resource", element: <PrivateResource /> },
    ],
  },
  { path: "*", element: <Navigate to='/' /> },
];

const AppRouter: React.FC = () => useRoutes(routes);

export default AppRouter;
