import React from "react";
import Hero from "../components/Hero";
import vP from "../assets/v-p.jpg";
import aP from "../assets/a-p.jpg";
import gmtv from "../assets/GMTV.png";
import dmr from "../assets/dmr-2.jpeg";
import { isIOSSafari } from "../utils";
import { Link } from "react-router-dom";

const Modal = () => {
  return (
    <>
      <input type='checkbox' id='my-modal-6' className='modal-toggle' />
      <div className='modal modal-bottom sm:modal-middle'>
        <div className='modal-box bg-white !text-gray-600'>
          <h3 className='font-bold text-lg'>Notification !!</h3>
          <p className='py-4'>
            Sorry Audio on demand is currently available only on Android and
            windows. IOS version will soon be available
          </p>
          <div className='modal-action'>
            <label htmlFor='my-modal-6' className='btn rounded-full'>
              Close
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

const MediaCenter: React.FC = () => {
  const isIOS = isIOSSafari();

  return (
    <div className='h-screen'>
      <Hero />
      <div className='bg-white sm:pt-52 pt-20 pb-8 container sm:m-auto px-6 !text-gray-600'>
        <h1 className='sm:text-5xl text-3xl font-bold text-center mb-3'>
          Media Center
        </h1>
        <div className='flex gap-5 flex-wrap justify-center'>
          <div className='card w-96 border shadow-md'>
            <figure className='px-10 pt-10'>
              <img
                src={vP}
                alt='VOD'
                loading='lazy'
                className='rounded-xl h-[163px]'
              />
            </figure>
            <div className='card-body items-center text-center'>
              <h2 className='card-title'>Video On Demand</h2>
              {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
              <div className='card-actions'>
                <Link
                  to='/vod'
                  className='btn btn-info btn-outline btn-sm rounded-full'
                >
                  Watch
                </Link>
              </div>
            </div>
          </div>
          <div className='card w-96 border shadow-md'>
            <figure className='px-10 pt-10'>
              <img
                src={aP}
                alt='AOD'
                loading='lazy'
                className='rounded-xl h-[163px]'
              />
            </figure>
            <div className='card-body items-center text-center'>
              <h2 className='card-title'>Audio On Demand</h2>
              {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
              <div className='card-actions'>
                {isIOS ? (
                  <label
                    htmlFor='my-modal-6'
                    className='btn btn-info btn-outline btn-sm rounded-full'
                  >
                    Listen
                  </label>
                ) : (
                  <a
                    className='btn btn-info btn-outline rounded-full btn-sm'
                    href={`https://www.audio.dominioncity.cc`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Listen
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className='card w-96 border shadow-md'>
            <figure className='px-10 pt-10'>
              <img
                src={gmtv}
                alt='GMTV'
                loading='lazy'
                className='rounded-xl'
              />
            </figure>
            <div className='card-body items-center text-center sm:mt-12'>
              <h2 className='card-title'>Global Missions TV (GMTV)</h2>
              {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
              <div className='card-actions'>
                <Link
                  to='/gmtv'
                  className='btn btn-info btn-outline rounded-full btn-sm'
                >
                  Watch
                </Link>
              </div>
            </div>
          </div>
          <div className='card w-96 border shadow-md'>
            <figure className='px-10 pt-10'>
              <img src={dmr} alt='DMR' loading='lazy' className='rounded-xl' />
            </figure>
            <div className='card-body items-center text-center'>
              <h2 className='card-title'>Dominion Mandate Radio</h2>
              {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
              <div className='card-actions'>
                <a
                  href='https://www.dominionmandateradio.com'
                  className='btn btn-info btn-outline rounded-full btn-sm'
                  target='_blank'
                  rel='noreferrer'
                >
                  Listen
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </div>
  );
};

export default MediaCenter;
