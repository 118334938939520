import React, { useEffect, useState, useMemo } from "react";
import { useGlobalState } from "../context/GlobalStore";
import { useNavigate } from "react-router-dom";
import ResourceMedia from "../components/ResourceMedia";

export const PrivateResource: React.FC = () => {
  const navigate = useNavigate();
  const {
    user,
    isFetchingUser,
    audioResource,
    videoResource,
    isFetchingRes,
    profileData,
  } = useGlobalState();
  const [activeNav, setActiveNav] = useState(2);

  useEffect(() => {
    if (!user && !isFetchingUser) {
      navigate("/login");
    }
  }, [user, navigate, isFetchingUser]);

  const privateAudio = useMemo(() => {
    return audioResource.filter((e) => e.level === "level2");
  }, [audioResource]);

  const privateVideo = useMemo(() => {
    return videoResource.filter((e) => e.level === "level2");
  }, [videoResource]);

  return (
    <div className='bg-white h-screen'>
      {isFetchingRes ? (
        <div className='flex items-center justify-center fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2  z-40'>
          {" "}
        </div>
      ) : (
        <>
          {profileData?.isPaymentConfirmed ? (
            <div className='sm:pt-16 pt-5 pb-32'>
              {activeNav === 1 && (
                <ResourceMedia list={privateVideo} title='Video' type='video' />
              )}
              {activeNav === 2 && (
                <ResourceMedia list={privateAudio} title='Audio' type='audio' />
              )}
            </div>
          ) : (
            <div className='flex items-center justify-center fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2  z-40'>
              {profileData ? (
                <div className='alert alert-error shadow-lg'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      className='stroke-current flex-shrink-0 w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                      ></path>
                    </svg>
                    <span>
                      {profileData.registrationLevel === "Level 1"
                        ? "You don't have access to this content. Upgrade you account to view content."
                        : "You payment is under verification. Please contact admin for more information."}
                    </span>
                  </div>
                </div>
              ) : (
                <div className='alert alert-error shadow-lg'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      className='stroke-current flex-shrink-0 w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                      ></path>
                    </svg>
                    <span>
                      Authorization. You don't have access to this content.
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      <div className='btm-nav'>
        <button
          className={
            activeNav === 2
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(2)}
        >
          <i className='fa-sharp fa-solid fa-headphones'></i>
          <span className='btm-nav-label'>Audio</span>
        </button>
        <button
          className={
            activeNav === 1
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(1)}
        >
          <i className='fa-solid fa-video'></i>
          <span className='btm-nav-label'>Video</span>
        </button>

        {/* <button
          className={
            activeNav === 3
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(3)}
        >
          <i className='fa-solid fa-book'></i>
          <span className='btm-nav-label'>Books</span>
        </button>
        <button
          className={
            activeNav === 4
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(4)}
        >
          <i className='fa-solid fa-file-lines'></i>
          <span className='btm-nav-label'>Texts</span>
        </button> */}
      </div>
    </div>
  );
};
