import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../context/GlobalStore";

const Modal = ({
  isOpen,
  onClose,
  level,
}: {
  isOpen: boolean;
  onClose: () => void;
  level: string;
}) => {
  return (
    <>
      {/* <input type='checkbox' id='my-modal-6' className={`modal-toggle `} /> */}
      <div
        className={`modal modal-bottom sm:modal-middle ${
          isOpen && "modal-open"
        }`}
      >
        <div className='modal-box bg-white !text-gray-600'>
          <h3 className='font-bold text-lg'>Notification !!</h3>
          <p className='py-4'>
            {level === "Level 1"
              ? "You don't have access to this content. Upgrade you account to view content."
              : "You payment is under verification. Please contact admin for more information."}
          </p>
          <div className='modal-action'>
            <button onClick={onClose} className='btn rounded-full btn-sm'>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const Session: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { profileData } = useGlobalState();

  const navigate = useNavigate();

  return (
    <>
      {profileData && profileData.isProfileActive && (
        <div className='alert alert-info shadow-md mt-3'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              className='stroke-current flex-shrink-0 w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              ></path>
            </svg>
            <div className='flex flex-col items-start'>
              <span className='font-bold text-start'>Registration Level</span>
              <span className='text-start'>
                <strong>{profileData.registrationLevel}:</strong>{" "}
                {profileData.registrationLevel === "Level 1"
                  ? "Free - Access to public sessions & limited resources"
                  : "Access to private sessions & resources"}
              </span>
            </div>
          </div>
        </div>
      )}
      <div className='flex gap-4 sm:flex-row flex-col'>
        <div className='card sm:w-96 w-auto bg-secondary text-secondary-content'>
          <div className='card-body'>
            <h2 className='card-title'>Free Sessions</h2>
            <p>Access to public sessions & limited resources</p>
            <div className='card-actions justify-end'>
              <Link
                to='/public-stream'
                className='btn bg-white text-gray-900 hover:text-white'
              >
                Watch
              </Link>
            </div>
          </div>
        </div>
        <div className='card sm:w-96 w-auto bg-primary text-primary-content'>
          <div className='card-body'>
            <h2 className='card-title'>Private Sessions</h2>
            <p>Access to private sessions & resources</p>
            <div className='card-actions justify-end'>
              <button
                className='btn bg-white text-gray-900 hover:text-white'
                onClick={() => {
                  if (
                    !profileData?.isPaymentConfirmed ||
                    profileData?.registrationLevel !== "Level 2"
                  ) {
                    setIsOpen(true);
                  } else {
                    navigate("/private-stream");
                  }
                }}
              >
                Watch
              </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          level={profileData?.registrationLevel || ""}
        />
      </div>
    </>
  );
};
export default Session;
