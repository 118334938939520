import React, { useState, useMemo } from "react";
import Hero from "../components/Hero";
import countryList from "react-select-country-list";
import {
  registerUser,
  addUserProfile,
  updateProfile,
} from "../firebase/firebase.config";
import { Store } from "react-notifications-component";
import { useGlobalState } from "../context/GlobalStore";
import { IUserProfile } from "../types";
import { usePaystackPayment } from "react-paystack";
import { getTotalAmountWithPer } from "../utils";
import { uuidv4 } from "@firebase/util";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../constants";

const Modal = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <>
      {/* <input type='checkbox' id='my-modal-6' className={`modal-toggle `} /> */}
      <div
        className={`modal modal-bottom sm:modal-middle ${
          isOpen && "modal-open"
        }`}
      >
        <div className='modal-box bg-white !text-gray-600'>
          <h3 className='font-bold text-lg'>Notification !!</h3>
          <p className='py-4'>
            Use any of the account details{" "}
            <Link className='text-sky-500 underline' to='/bank-transfer'>
              HERE
            </Link>{" "}
            to make payment and send prove of payment to admin. You can login to
            your dashboard with your email and password.
          </p>
          <div className='modal-action'>
            <Link to='/bank-transfer'>
              <label
                htmlFor='my-modal-6'
                className='btn btn-info rounded-full btn-sm'
              >
                Bank Transfer Details
              </label>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

const Register: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    registrationLevel: "",
    phone: "",
    email: "",
    password: "",
    paymentChannel: "",
    currency: "",
  });
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { user, getProfile } = useGlobalState();
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  const config: any = {
    reference: uuidv4(),
    email: formData.email,
    amount:
      getTotalAmountWithPer(formData.currency === "Naira" ? 150000 : 200, 1.5) *
      100,
    publicKey: process.env.REACT_APP_MAIN_PUBLIC_STACK_KEY,
    subaccount: process.env.REACT_APP_REV_DAVID_MAIN,
  };

  const initializePayment = usePaystackPayment(config);

  const saveToDB = async (verified: boolean) => {
    updateUserProfileData({
      registrationLevel: "Level 2",
      isProfileActive: true,
      isPaymentConfirmed: verified,
      currency: formData.currency,
    });
  };

  const onClose = () => {
    Store.addNotification({
      title: "Notification!",
      message: "Payment cancelled",
      type: "info",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };
  const onSuccess = (data?: any) => {
    let reference = "";
    if (typeof data === "object" && !Array.isArray(data) && data !== null) {
      reference = data.reference;
    }

    if (typeof data === "string") {
      reference = data;
    }

    if (reference) {
      axios
        .post(`${baseUrl}/give/verify`, { reference })
        .then(({ data }) => {
          if (data && data.data.status === "success") {
            saveToDB(true);
            Store.addNotification({
              title: "Notification!",
              message: "Payment successful",
              type: "success",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          } else {
            Store.addNotification({
              title: "Notification!",
              message:
                "Payment sent, Please contact the admin to verify payment",
              type: "info",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            saveToDB(false);
          }
        })
        .catch(() => {
          saveToDB(false);
          Store.addNotification({
            title: "Notification!",
            message: "Payment sent, Please contact the admin to verify payment",
            type: "info",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        });
    } else {
      saveToDB(false);
      Store.addNotification({
        title: "Notification!",
        message: "Payment sent, Please contact the admin to verify payment",
        type: "info",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (step === 1) {
      setIsLoading(true);
      createAccount();
    } else {
      if (formData.registrationLevel) {
        if (formData.registrationLevel === "Level 1") {
          updateUserProfileData({
            registrationLevel: "Level 1",
            isProfileActive: true,
          });
        } else {
          if (
            formData.paymentChannel === "Card" &&
            formData.currency === "Naira"
          ) {
            initializePayment(onSuccess, onClose);
          } else {
            updateUserProfileData(
              {
                registrationLevel: "Level 2",
                isProfileActive: true,
                isPaymentConfirmed: false,
                currency: formData.currency || "Bank Transfer",
              },
              true
            );
            setIsOpen(true);
          }
        }
      }
    }
  };

  const createAccount = async () => {
    try {
      const userCredential = await registerUser(
        formData.email,
        formData.password
      );

      if (userCredential.user) {
        addNewUserProfile(userCredential.user.uid);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.code) {
        Store.addNotification({
          title: "Notification!",
          message: error.code,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        Store.addNotification({
          title: "Notification!",
          message: "An error occurred. Please try again later",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  const addNewUserProfile = async (uuid: string) => {
    try {
      await addUserProfile(uuid, {
        fullName: formData.fullName,
        country: formData.country,
        phone: formData.phone,
        email: formData.email,
        isPaymentConfirmed: false,
        isProfileActive: false,
      });
      Store.addNotification({
        title: "Notification!",
        message:
          "Account created successfully. Please select registration level to activate account and complete registration",
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 9000,
          onScreen: true,
        },
      });
      setIsLoading(false);
      setStep(2);
    } catch (error: any) {
      setIsLoading(false);
      if (error.code) {
        Store.addNotification({
          title: "Notification!",
          message: error.code,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        Store.addNotification({
          title: "Notification!",
          message: "An error occurred. Please try again later",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  const updateUserProfileData = async (
    updateData: Partial<IUserProfile>,
    noNavigate: boolean = false
  ) => {
    setIsLoading(true);
    try {
      await updateProfile(user?.uid!, updateData);
      setIsLoading(false);
      getProfile(user?.uid!);
      if (!noNavigate) {
        navigate("/dashboard");
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.code) {
        Store.addNotification({
          title: "Notification!",
          message: error.code,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        Store.addNotification({
          title: "Notification!",
          message: "An error occurred. Please try again later",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className='h-screen'>
      <Hero />
      <div className=' bg-white sm:pt-[210px] pt-20 pb-8 text-gray-600'>
        <div className='text-center'>
          <div className='px-3'>
            <h1 className='sm:text-5xl text-3xl font-bold'>Register</h1>
            <div className='flex justify-center sm:w-1/2 w-full sm:m-auto pt-5'>
              <form
                className='card flex-shrink-0 w-full shadow-md border'
                onSubmit={onSubmit}
              >
                <div className='card-body'>
                  <h2 className='card-title'>
                    {step === 1 ? "Account details" : "Registration level"}
                  </h2>
                  {step === 2 && (
                    <div className='alert alert-info shadow-md'>
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          className='stroke-current flex-shrink-0 w-6 h-6'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                          ></path>
                        </svg>
                        <div className='flex flex-col items-start'>
                          <span className='font-bold text-start'>
                            Registration Levels
                          </span>
                          <span className='text-start'>
                            <strong>Level 1 :</strong> Free - access to public
                            sessions & limited resources
                          </span>
                          <span className='text-start'>
                            <strong>Level 2 :</strong> $200, £200, €200,
                            ₦150,000 - access to private sessions & resources
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {step === 1 ? (
                    <>
                      <div className='form-control'>
                        <label className='label' htmlFor='name'>
                          <span className='label-text'>Full name</span>
                        </label>
                        <input
                          id='name'
                          type='text'
                          name='fullName'
                          placeholder='Full name'
                          className='input input-bordered input-info bg-white'
                          required
                          onChange={onChange}
                          value={formData.fullName}
                        />
                      </div>
                      <div className='form-control'>
                        <label className='label' htmlFor='email'>
                          <span className='label-text'>Email</span>
                        </label>
                        <input
                          id='email'
                          type='email'
                          name='email'
                          placeholder='Email'
                          className='input input-bordered input-info bg-white'
                          required
                          onChange={onChange}
                          value={formData.email}
                        />
                      </div>
                      <div className='form-control'>
                        <label className='label' htmlFor='phone'>
                          <span className='label-text'>
                            Phone Number (with country code)
                          </span>
                        </label>
                        <input
                          id='phone'
                          type='phone'
                          name='phone'
                          placeholder='Phone Number'
                          className='input input-bordered input-info bg-white'
                          required
                          onChange={onChange}
                          value={formData.phone}
                        />
                      </div>
                      <div className='form-control'>
                        <label className='label' htmlFor='password'>
                          <span className='label-text'>Password</span>
                        </label>
                        <input
                          id='password'
                          type='password'
                          name='password'
                          placeholder='Password'
                          className='input input-bordered input-info bg-white'
                          required
                          onChange={onChange}
                          value={formData.password}
                        />
                      </div>
                      <div className='form-control'>
                        <label className='label' htmlFor='country'>
                          <span className='label-text'>Country</span>
                        </label>
                        <select
                          className='select select-bordered select-info bg-white'
                          id='country'
                          name='country'
                          required
                          onChange={onChange}
                          value={formData.country}
                        >
                          <option disabled value=''>
                            Select country
                          </option>
                          {options.map((e) => (
                            <option key={e.value} value={e.label}>
                              {e.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='form-control'>
                        <label className='label' htmlFor='level'>
                          <span className='label-text'>Level</span>
                        </label>
                        <select
                          className='select select-bordered select-info bg-white'
                          id='level'
                          name='registrationLevel'
                          required
                          onChange={onChange}
                          value={formData.registrationLevel}
                        >
                          <option disabled value=''>
                            Select level
                          </option>
                          <option value='Level 1'>Level 1</option>
                          <option value='Level 2'>Level 2</option>
                        </select>
                      </div>
                      {formData.registrationLevel === "Level 2" ? (
                        <>
                          <div className='form-control'>
                            <label className='label' htmlFor='channel'>
                              <span className='label-text'>
                                Payment channel
                              </span>
                            </label>
                            <select
                              className='select select-bordered select-info bg-white'
                              id='channel'
                              name='paymentChannel'
                              required
                              onChange={onChange}
                              value={formData.paymentChannel}
                            >
                              <option disabled value=''>
                                Select payment channel
                              </option>
                              <option value='Card'>
                                Card Payment (Local and International){" "}
                              </option>
                              <option value='Bank'>Bank Transfer</option>
                            </select>
                          </div>
                          {formData.paymentChannel === "Card" ? (
                            <div className='form-control'>
                              <label className='label' htmlFor='currency'>
                                <span className='label-text'>Currency</span>
                              </label>
                              <select
                                className='select select-bordered select-info bg-white'
                                id='currency'
                                name='currency'
                                required
                                onChange={onChange}
                                value={formData.currency}
                              >
                                <option disabled value=''>
                                  Select currency
                                </option>
                                <option value='Naira'>Naira (₦150,000)</option>
                                <option value='Dollar'>USD ($200)</option>
                                <option value='Pounds'>Pounds (£200)</option>
                                <option value='Euros'>Euro (€200)</option>
                              </select>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  )}

                  <div className='card-actions justify-end mt-4'>
                    <button
                      className='btn btn-info btn-sm'
                      type='submit'
                      disabled={isLoading}
                    >
                      {step === 1
                        ? "Create Account"
                        : `${
                            !formData.registrationLevel ||
                            formData.registrationLevel === "Level 1"
                              ? "Confirm"
                              : "Make Payment"
                          }`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} />
    </div>
  );
};

export default Register;
