import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalState } from "../context/GlobalStore";

const Modal = ({
  isOpen,
  onClose,
  level,
}: {
  isOpen: boolean;
  onClose: () => void;
  level: string;
}) => {
  return (
    <>
      {/* <input type='checkbox' id='my-modal-6' className={`modal-toggle `} /> */}
      <div
        className={`modal modal-bottom sm:modal-middle ${
          isOpen && "modal-open"
        }`}
      >
        <div className='modal-box bg-white !text-gray-600'>
          <h3 className='font-bold text-lg'>Notification !!</h3>
          <p className='py-4'>
            {level === "Level 1"
              ? "You don't have access to this content. Upgrade you account to view content."
              : "You payment is under verification. Please contact admin for more information."}
          </p>
          <div className='modal-action'>
            <button onClick={onClose} className='btn rounded-full btn-sm'>
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const Resources: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { profileData } = useGlobalState();

  const navigate = useNavigate();

  return (
    <div className='flex gap-4 sm:flex-row flex-col mt-3'>
      <div className='card sm:w-96 w-auto  bg-secondary text-secondary-content'>
        <div className='card-body items-center text-center'>
          <h2 className='card-title'>Public Resources</h2>
          {/* <p>We are using cookies for no reason.</p> */}
          <div className='card-actions justify-end'>
            <Link
              to='/public-resource'
              className='btn bg-white text-gray-900 hover:text-white'
            >
              View
            </Link>
          </div>
        </div>
      </div>
      <div className='card sm:w-96 w-auto bg-primary text-primary-content'>
        <div className='card-body items-center text-center'>
          <h2 className='card-title'>Private Resources</h2>
          {/* <p>We are using cookies for no reason.</p> */}
          <div className='card-actions justify-end'>
            <button
              className='btn bg-white text-gray-900 hover:text-white'
              onClick={() => {
                if (
                  !profileData?.isPaymentConfirmed ||
                  profileData?.registrationLevel !== "Level 2"
                ) {
                  setIsOpen(true);
                } else {
                  navigate("/private-resource");
                }
              }}
            >
              View
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        level={profileData?.registrationLevel || ""}
      />
    </div>
  );
};

export default Resources;
