import React, { useRef } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Hero from "../components/Hero";
import { Store } from "react-notifications-component";

const KEY = "mbjbydwz";

const Contact: React.FC = () => {
  const [state, handleSubmit] = useForm(KEY);

  const formRef = useRef<any>(null);

  return (
    <div className='h-screen'>
      <Hero />
      <div className='bg-white sm:pt-52 pt-20 pb-8 container sm:m-auto sm:px-6  !text-gray-600'>
        <h1 className='sm:text-5xl text-3xl font-bold text-center mb-3'>
          Contact
        </h1>

        <div className=''>
          <div className='hero-content lg:!items-start flex-col lg:flex-row-reverse'>
            <div className='flex gap-5 flex-wrap sm:justify-start justify-center'>
              <div className='card sm:w-96 w-72 card-compact shadow-md border border-info'>
                <div className='card-body'>
                  <h2 className='card-title'>Contact</h2>
                  <a href='mailto:mail@davidogbueli.org'>
                    mail@davidogbueli.org
                  </a>
                  <a href='tel:+2348035508230'>+234-8035508230</a>
                  <a href='tel:+2348034954566'>+234-8034954566</a>
                </div>
              </div>
              <div className='card sm:w-96 w-72 card-compact shadow-md border border-info'>
                <div className='card-body'>
                  <h2 className='card-title'>US Office</h2>
                  <p>
                    1127 Misty Valley Court, Lawrenceville, <br />
                    GA. 30045 (Georgia) <br />
                    United States
                  </p>
                  <a href='tel:+14705352006'>+1-470-535-2006 </a>
                  <a href='tel:+16786227090'>+1-678-622-7090</a>
                </div>
              </div>
              <div className='card sm:w-96 w-72 card-compact shadow-md border border-info'>
                <div className='card-body'>
                  <h2 className='card-title'>UK Office</h2>
                  <p>
                    The Hub, 123 Star Lane, <br />
                    London <br />
                    E16 4PZ <br />
                    United Kingdom
                  </p>
                  <a href='tel:+447956670069'>+44-7956670069</a>
                  <a href='tel:+447760919119'>+44-7760919119</a>
                </div>
              </div>
              <div className='card sm:w-96 w-72 card-compact shadow-md border border-info '>
                <div className='card-body'>
                  <h2 className='card-title'>Abuja Office</h2>
                  <p>
                    17 Oladipo Diya Way, <br />
                    Gudu District, <br />
                    FCT, Abuja, <br />
                    Nigeria
                  </p>
                  <a href='tel:+2348038246281'>+234-8038246281</a>
                  <a href='tel:+2347060574969'>+234-7060574969</a>
                </div>
              </div>
              <div className='card sm:w-96 w-72 card-compact shadow-md border border-info '>
                <div className='card-body'>
                  <h2 className='card-title'>Lagos Office</h2>
                  <p>
                    Km 22, Lekki-Epe Expressway, <br />
                    beside Lagos Business School, <br />
                    Ajah, Lekki, Lagos
                    <br />
                    Nigeria
                  </p>
                  <a href='tel:+2348034954566'>+234-8034954566</a>
                  <a href='tel:+2348035508230'>+234-8035508230</a>
                </div>
              </div>
            </div>
            <form
              ref={formRef}
              className='card flex-shrink-0 w-full max-w-sm shadow-md border'
              onSubmit={(e) => {
                handleSubmit(e)
                  .then((res) => {
                    Store.addNotification({
                      title: "Notification!",
                      message:
                        "Message submitted successfully. I will respond to you as soon as possible.",
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                      },
                    });

                    if (formRef.current) {
                      formRef.current.reset();
                    }
                  })
                  .catch(() => {
                    Store.addNotification({
                      title: "Notification!",
                      message: "An error occurred. Please try again later",
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__fadeIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 5000,
                        onScreen: true,
                      },
                    });
                  });
              }}
            >
              <div className='card-body'>
                <h2 className='card-title'>Contact Form</h2>
                <div className='form-control'>
                  <label className='label' htmlFor='name'>
                    <span className='label-text'>Full name</span>
                  </label>
                  <input
                    id='name'
                    type='text'
                    name='name'
                    placeholder='Full name'
                    className='input input-bordered input-info bg-white'
                    required
                  />
                  <ValidationError
                    prefix='Name'
                    field='name'
                    errors={state.errors}
                  />
                </div>
                <div className='form-control'>
                  <label className='label' htmlFor='email'>
                    <span className='label-text'>Email</span>
                  </label>
                  <input
                    id='email'
                    type='email'
                    name='email'
                    placeholder='Email'
                    className='input input-bordered input-info bg-white'
                    required
                  />
                  <ValidationError
                    prefix='Email'
                    field='email'
                    errors={state.errors}
                  />
                  <label className='label' htmlFor='message'>
                    <span>Message</span>
                  </label>
                  <textarea
                    id='message'
                    name='message'
                    className='textarea textarea-bordered textarea-info bg-white'
                    placeholder='Type message here'
                    required
                  ></textarea>
                  <ValidationError
                    prefix='Message'
                    field='message'
                    errors={state.errors}
                  />
                </div>
                <div className='form-control mt-6'>
                  <button
                    type='submit'
                    disabled={state.submitting}
                    className='btn btn-info rounded-full btn-sm'
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
