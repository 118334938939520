import React, { createContext, useState, useContext, useEffect } from "react";
import youtubeSearch from "../api/youtubeSearch";
import {
  IVideoItem,
  IPlayingItem,
  IUserProfile,
  IResourceMedia,
} from "../types";
import { mapPlaylistToVideo } from "../utils";
import axios from "axios";
import { User } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth, getUserProfile } from "../firebase/firebase.config";
import { Store } from "react-notifications-component";
import sanity from "../sanity";

const audioQuery = `
*[_type == "audiomediaGLF"] | order(publishedAt) {
  _id,
  title,
  "linkUrl": audioUrl,
  "artwork": coverImage.asset->url,
  "fileUrl": file.asset->url,
  "artist": author,
  publishedAt,
  level
}[0...50]
`;

const videoQuery = `
*[_type == "videomediaGLF"] | order(publishedAt) {
  _id,
  title,
  "linkUrl": audioUrl,
  "artwork": coverImage.asset->url,
  "fileUrl": file.asset->url,
  "artist": author,
  publishedAt,
  level
}[0...50]
`;

interface ContextI {
  videoArray: IVideoItem[];
  setPlayingItem: React.Dispatch<React.SetStateAction<IPlayingItem | null>>;
  playingItem: IPlayingItem | null;
  user: User | null;
  getProfile: (uid: string) => Promise<void>;
  profileData: IUserProfile | null;
  isFetchingUser: boolean;
  isFetchingProfile: boolean;
  audioResource: IResourceMedia[];
  videoResource: IResourceMedia[];
  isFetchingRes: boolean;
}

const initialState: ContextI = {
  videoArray: [],
  setPlayingItem: () => {},
  playingItem: null,
  user: null,
  getProfile: async () => {},
  profileData: null,
  isFetchingUser: true,
  isFetchingProfile: false,
  audioResource: [],
  videoResource: [],
  isFetchingRes: false,
};

const StateContext = createContext<ContextI>(initialState);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [videoArray, setVideoArray] = useState<IVideoItem[]>([]);
  const [playingItem, setPlayingItem] = useState<IPlayingItem | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [profileData, setProfileData] = useState<IUserProfile | null>(null);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [isFetchingProfile, setIsFetchingProfile] = useState(false);
  const [audioResource, setAudioResource] = useState<IResourceMedia[]>([]);
  const [videoResource, setVideoResource] = useState<IResourceMedia[]>([]);
  const [isFetchingRes, setIsFetchingRes] = useState(false);

  const getProfile = async (uid: string) => {
    try {
      const profileSnapShot = await getUserProfile(uid);
      setProfileData(profileSnapShot.data() as IUserProfile);
      setIsFetchingProfile(false);
    } catch (error: any) {
      setIsFetchingProfile(false);
      if (error.code) {
        Store.addNotification({
          title: "Notification!",
          message: error.code,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        Store.addNotification({
          title: "Notification!",
          message:
            "An error occurred getting account data. Please try again later",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  const getPlayListItems = async (playlistId: string) => {
    const res = await youtubeSearch.get("playlistItems", {
      params: {
        playlistId,
        maxResults: 50,
      },
    });

    return res.data.items;
  };

  const batchRequest = async (axiosArray: any, playlists: any) => {
    try {
      const response = await axios.all(axiosArray);

      if (response.length) {
        const songData = mapPlaylistToVideo(playlists, response);
        localStorage.setItem("homePageVideoObj", JSON.stringify(songData));

        setVideoArray(songData);
      }
    } catch (error) {}
  };

  const getPlayLists = async () => {
    const res = await youtubeSearch.get("playlists", {
      params: {
        maxResults: 50,
        channelId: process.env.REACT_APP_ChannelId,
      },
    });

    const playlists = res.data.items;
    let axiosArray: Promise<any>[] = [];

    for (const list of playlists) {
      axiosArray.push(getPlayListItems(list.id as string));
    }

    batchRequest(axiosArray, playlists);
  };

  const fetchFromApi = () => {
    getPlayLists();
  };

  useEffect(() => {
    const authObserverUnsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        getProfile(user.uid);
        setIsFetchingUser(false);
        setIsFetchingProfile(true);
      } else {
        setUser(null);
        setIsFetchingUser(false);
      }
    });

    return () => {
      authObserverUnsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      setIsFetchingRes(true);
      const audio = await sanity.fetch<IResourceMedia[]>(audioQuery);
      const videos = await sanity.fetch<IResourceMedia[]>(videoQuery);
      setAudioResource(audio);
      setVideoResource(videos);
      setIsFetchingRes(false);
      // console.log({ audio });
      // console.log({ videos });
    } catch (error) {
      setIsFetchingRes(false);
    }
  };

  useEffect(() => {
    const startingTime = new Date();
    const storedTime = localStorage.getItem("videoTime");
    const saveVideo = JSON.parse(
      localStorage.getItem("homePageVideoObj") as any
    ) as IVideoItem[];

    // console.log({ saveVideo });

    const checkTimeAndFetch = () => {
      const timeElapsed = (new Date() as any) - Date.parse(storedTime as any); //parse the date

      const timeElapsedInHr = timeElapsed / (1000 * 60 * 60); //convert ms into hr

      // if time is more than 12 hr we will fetch from the api

      // console.log({ timeElapsedInHr });

      // console.log("Saved song", saveVideo);
      if (timeElapsedInHr > 12 || saveVideo?.length === 0) {
        // console.log("Called");

        fetchFromApi();
        localStorage.setItem("videoTime", startingTime as any); //dont forgot to update the time
      } else {
        setVideoArray(saveVideo);
      }
    };

    if (!storedTime) {
      // if no time stored we will store it
      localStorage.setItem("videoTime", startingTime as any);
      fetchFromApi();
    } else {
      checkTimeAndFetch();
    }
    // eslint-disable-next-line
  }, []);

  // console.log({ videoArray });

  return (
    <StateContext.Provider
      value={{
        videoArray,
        setPlayingItem,
        playingItem,
        user,
        getProfile,
        profileData,
        isFetchingUser,
        isFetchingProfile,
        audioResource,
        videoResource,
        isFetchingRes,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useGlobalState = () => useContext(StateContext);
