import axios from "axios";

export default axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params: {
    part: "snippet",
    // videoCategoryId: "10",
    type: "video",
    key: process.env.REACT_APP_YouTube_Keys,
    channelId: process.env.REACT_APP_ChannelId,
  },
});
