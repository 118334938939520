import React from "react";
import { useNavigate } from "react-router-dom";
import Hero from "../components/Hero";
import VideoCard from "../components/VideoCard";
import { useGlobalState } from "../context/GlobalStore";

const VideoPlaylist: React.FC = () => {
  const { videoArray, setPlayingItem } = useGlobalState();
  const navigate = useNavigate();

  return (
    <div className='h-screen'>
      <Hero />
      <div className='bg-white sm:pt-52 pt-20 pb-8 container sm:m-auto px-6 !text-gray-600'>
        <h1 className='sm:text-5xl text-3xl font-bold text-center mb-3 bg-transparent'>
          Video On Demand (VOD)
        </h1>
        {videoArray.map((video) => (
          <React.Fragment key={video.playlistId}>
            {video.playlistItems.length ? (
              <div className='!mb-10'>
                <h6 className='font-bold'>{video.playlistTitle}</h6>
                <div className='carousel carousel-center bg-transparent'>
                  {video.playlistItems.map((item) => (
                    <VideoCard
                      key={item.id}
                      videoData={item}
                      handleClick={() => {
                        setPlayingItem({
                          videoData: item,
                          items: video.playlistItems,
                        });
                        navigate("/player");
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default VideoPlaylist;
