import React from "react";
// import heroBg from "../assets/bg-2.jpg";
import hl from "../assets/hn-l.png";
import hp from "../assets/hn-p.png";
import { useMediaQuery } from "react-responsive";

const Hero: React.FC = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 640px)",
  });

  return (
    <div className='carousel w-full fixed z-30'>
      <div id='item1' className='carousel-item w-full relative'>
        <img
          src={isDesktopOrLaptop ? hl : hp}
          className='w-full'
          loading='lazy'
          alt='Hero'
          // height={260}
        />
        {/* <h1 className=' absolute left-2 bottom-2 text-white font-mono italic'>
          Dr David Ogbueli
        </h1> */}
      </div>
    </div>
  );
};

export default Hero;
