// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  setDoc,
  doc,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { IUserProfile } from "../types";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC9l1IxPWQC4PmUrGsstL_DzY3sB5OTKA8",
  authDomain: "global-leadership-forum.firebaseapp.com",
  projectId: "global-leadership-forum",
  storageBucket: "global-leadership-forum.appspot.com",
  messagingSenderId: "40495871979",
  appId: "1:40495871979:web:b6377ba040ebf0775acbae",
  measurementId: "G-3DL7F17XD7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const timestamp = serverTimestamp();
const analytics = getAnalytics(app);

const registerUser = async (email: string, password: string) =>
  await createUserWithEmailAndPassword(auth, email, password);

const loginUser = async (email: string, password: string) =>
  await signInWithEmailAndPassword(auth, email, password);

const addUserProfile = async (uuid: string, profileData: IUserProfile) =>
  await setDoc(doc(db, "profiles", uuid), {
    ...profileData,
    createdAt: timestamp,
  });

const getUserProfile = async (uuid: string) => {
  const ref = doc(db, "profiles", uuid);
  return await getDoc(ref);
};

const updateProfile = async (
  uuid: string,
  updateData: Partial<IUserProfile>
) => {
  const ref = doc(db, "profiles", uuid);
  return await updateDoc(ref, updateData);
};

const logout = async () => await signOut(auth);

export {
  app,
  db,
  auth,
  registerUser,
  loginUser,
  addUserProfile,
  getUserProfile,
  timestamp,
  updateProfile,
  logout,
  analytics,
};
