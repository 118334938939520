export const isIOSSafari = () => {
  var isSafari = window.safari !== undefined;
  return !!(navigator.userAgent.match(/(iPod|iPhone|iPad)/) || isSafari);
};

export const mapPlaylistToVideo = (playlists, playlistItems) => {
  const newArray = [];

  playlists.forEach((e) => {
    playlistItems.forEach((j) => {
      if (j.length && j[0].snippet.playlistId.toString() === e.id.toString()) {
        newArray.push({
          playlistTitle: e.snippet.title,
          playlistId: e.id,
          playlistItems: j.filter(
            (e) =>
              !e.snippet.title.includes("Private video") &&
              !e.snippet.title.includes("Deleted video")
          ),
        });
      }
    });
  });

  // console.log({ newArray });

  return newArray;
};

export const getTotalAmountWithPer = (amount, percentage) => {
  const per = (percentage / 100) * amount;

  return per + amount;
};
