import React from "react";
import { IPlayListItem } from "../types";

interface PlayerProps {
  playlistItems: IPlayListItem[];
  playingId: string;
  isPlaying: boolean;
  onPlayPausePressed: (id: string) => void;
  loadVideo: (video: IPlayListItem) => void;
}

const Player: React.FC<PlayerProps> = ({
  playlistItems,
  playingId,
  isPlaying,
  onPlayPausePressed,
  loadVideo,
}) => {
  return (
    <div className='mt-10 sm:p-0 p-3 flex flex-wrap justify-center gap-4'>
      {playlistItems.map((item) => (
        <div
          className={`card shadow-md card-compact w-64 cursor-pointer ${
            playingId === item.id ? "border-info border-2" : "border"
          }`}
          key={item.id}
          onClick={() => {
            if (playingId === item.id) {
              onPlayPausePressed(item.id);
            } else {
              loadVideo(item);
            }
          }}
        >
          <figure>
            <img
              src={item.snippet?.thumbnails?.high?.url}
              alt={item.snippet.title}
              loading='lazy'
            />
          </figure>
          <div className='card-body'>
            {/* <h2 className='card-title'>Shoes!</h2> */}
            <p>{item.snippet.title.slice(0, 70) + " ..."}</p>
            <div className='card-actions justify-end'>
              <button className='btn btn-info btn-circle'>
                {playingId === item.id && isPlaying ? (
                  <i className='fa-solid fa-pause'></i>
                ) : (
                  <i className='fa-solid fa-play'></i>
                )}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Player;
