import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-notifications-component/dist/theme.css";
import reportWebVitals from "./reportWebVitals";
import Providers from "./providers";
import AppRouter from "./routes/routes";
import Wrapper from "./components/Wrapper";
import "react-jinke-music-player/assets/index.css";
import "video-react/dist/video-react.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Providers>
    <Wrapper>
      <AppRouter />
    </Wrapper>
  </Providers>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
