import React, { useEffect, useState, useMemo } from "react";
import { useGlobalState } from "../context/GlobalStore";
import { useNavigate } from "react-router-dom";
import ResourceMedia from "../components/ResourceMedia";

const PublicResource: React.FC = () => {
  const navigate = useNavigate();
  const { user, isFetchingUser, audioResource, videoResource, isFetchingRes } =
    useGlobalState();
  const [activeNav, setActiveNav] = useState(2);

  useEffect(() => {
    if (!user && !isFetchingUser) {
      navigate("/login");
    }
  }, [user, navigate, isFetchingUser]);

  const publicAudio = useMemo(() => {
    return audioResource.filter((e) => e.level === "level1");
  }, [audioResource]);

  const publicVideo = useMemo(() => {
    return videoResource.filter((e) => e.level === "level1");
  }, [videoResource]);

  return (
    <div className='bg-white h-screen'>
      {isFetchingRes ? (
        <div className='flex items-center justify-center fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2  z-40'>
          {" "}
        </div>
      ) : (
        <div className='sm:pt-16 pt-5 pb-32'>
          {activeNav === 1 && (
            <ResourceMedia list={publicVideo} title='Video' type='video' />
          )}
          {activeNav === 2 && (
            <ResourceMedia list={publicAudio} title='Audio' type='audio' />
          )}
        </div>
      )}
      <div className='btm-nav'>
        <button
          className={
            activeNav === 2
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(2)}
        >
          <i className='fa-sharp fa-solid fa-headphones'></i>
          <span className='btm-nav-label'>Audio</span>
        </button>
        <button
          className={
            activeNav === 1
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(1)}
        >
          <i className='fa-solid fa-video'></i>
          <span className='btm-nav-label'>Video</span>
        </button>

        {/* <button
          className={
            activeNav === 3
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(3)}
        >
          <i className='fa-solid fa-book'></i>
          <span className='btm-nav-label'>Books</span>
        </button>
        <button
          className={
            activeNav === 4
              ? "bg-info text-white"
              : "bg-gray-200 text-gray-900 border-l border-r"
          }
          onClick={() => setActiveNav(4)}
        >
          <i className='fa-solid fa-file-lines'></i>
          <span className='btm-nav-label'>Texts</span>
        </button> */}
      </div>
    </div>
  );
};

export default PublicResource;
