import dcG from "../assets/dc.jpg";
import dli from "../assets/dli.jpg";
import ghf from "../assets/ghf.jpg";
import hd from "../assets/hd.jpg";
import pi from "../assets/pi.jpg";
import sw from "../assets/sw.jpg";
import glf from "../assets/GLF.jpg";
import gmn from "../assets/GMN.jpg";

export const ministryData = [
  {
    title: "Dominion City",
    description:
      "A church ministry founded to raise leaders that transform societies with over 1,000 chapters worldwide with presence in America, Europe, the Middle East, Asia and Africa with regional offices in US, UK & Nigeria.",
    img: dcG,
    link: "https://www.dominioncity.cc",
  },
  {
    title: "Global Leadership Forum",
    description:
      "A mentorship & training platform established for human capital development & transformational leadership for exponential growth in performance & productivity in any field of endeavour - ministry, career, enterprise, politics, government etc.",
    img: glf,
    link: "",
  },
  {
    title: "GMN - Global Missions Network",
    description:
      "An alliance of global apostolic leaders with a common mandate to expand the reach of the Gospel & transforming nations.",
    img: gmn,
    link: "",
  },
  {
    title: "Dominion Leadership Institute",
    description:
      "A training institute established for personal & ministry leadership development. DLI has produced over 30,000 graduates making tremendous impacts around the world.",
    img: dli,
    link: "",
  },
  {
    title: "Golden Heart Foundation",
    description:
      "A non-profit organization with a strong focus on youth empowerment & development. The host of the National Youth Summit, an annual youth program with over 50,000 participants annually from across Africa.",
    img: ghf,
    link: "",
  },
  {
    title: "Huram Development",
    description:
      "A building & construction company with strong competencies in architecture & project management having ongoing large-scale projects of mega auditoriums, estates and universities.",
    img: hd,
    link: "",
  },
  {
    title: "Priesthood Institute",
    description:
      "A ministerial college established to train ministry professionals in competence, capacity, charisma & skillset required for the last day move of God.",
    img: pi,
    link: "",
  },
  {
    title: "Shalom World",
    description:
      "A multi-commerce business venture with a supply chain for marketing & distribution of ministry books, publications & other ministry commercial assets.",
    img: sw,
    link: "",
  },
];
