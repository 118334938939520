import React, { useState, useMemo, useEffect } from "react";
import { useGlobalState } from "../context/GlobalStore";
import countryList from "react-select-country-list";
import { Store } from "react-notifications-component";
import { IUserProfile } from "../types";
import { updateProfile } from "../firebase/firebase.config";
import { usePaystackPayment } from "react-paystack";
import { getTotalAmountWithPer } from "../utils";
import { uuidv4 } from "@firebase/util";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../constants";

const Modal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  return (
    <>
      {/* <input type='checkbox' id='my-modal-6' className={`modal-toggle `} /> */}
      <div
        className={`modal modal-bottom sm:modal-middle ${
          isOpen && "modal-open"
        }`}
      >
        <div className='modal-box bg-white !text-gray-600'>
          <h3 className='font-bold text-lg'>Confirmation !!</h3>
          <p className='py-4'>Do you want to continue with account upgrade ?</p>
          <div className='modal-action'>
            <button onClick={onClose} className='btn rounded-full btn-sm'>
              No
            </button>
            <button
              onClick={onConfirm}
              className='btn rounded-full btn-sm btn-info'
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const AccountInfo: React.FC = () => {
  const { profileData, isFetchingProfile, user, getProfile } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    country: "",
    registrationLevel: "",
    phone: "",
    email: "",
    paymentChannel: "",
    currency: "",
  });

  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  const config: any = {
    reference: uuidv4(),
    email: formData.email,
    amount:
      getTotalAmountWithPer(formData.currency === "Naira" ? 150000 : 200, 1.5) *
      100,
    publicKey: process.env.REACT_APP_MAIN_PUBLIC_STACK_KEY,
    subaccount: process.env.REACT_APP_REV_DAVID_MAIN,
  };

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    if (profileData && profileData.isProfileActive) {
      setFormData((prev) => ({
        ...prev,
        fullName: profileData.fullName,
        country: profileData.country,
        registrationLevel: profileData.registrationLevel!,
        phone: profileData.phone,
        email: profileData.email,
      }));
    }
  }, [profileData]);

  const saveToDB = async (verified: boolean) => {
    updateUserProfileData({
      registrationLevel: "Level 2",
      isProfileActive: true,
      isPaymentConfirmed: verified,
      currency: formData.currency,
    });
  };

  const onClose = () => {
    Store.addNotification({
      title: "Notification!",
      message: "Payment cancelled",
      type: "info",
      insert: "top",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };
  const onSuccess = (data?: any) => {
    let reference = "";
    if (typeof data === "object" && !Array.isArray(data) && data !== null) {
      reference = data.reference;
    }

    if (typeof data === "string") {
      reference = data;
    }

    if (reference) {
      axios
        .post(`${baseUrl}/give/verify`, { reference })
        .then(({ data }) => {
          if (data && data.data.status === "success") {
            saveToDB(true);
            Store.addNotification({
              title: "Notification!",
              message: "Payment successful",
              type: "success",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          } else {
            Store.addNotification({
              title: "Notification!",
              message:
                "Payment sent, Please contact the admin to verify payment",
              type: "info",
              insert: "top",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
            saveToDB(false);
          }
        })
        .catch(() => {
          saveToDB(false);
          Store.addNotification({
            title: "Notification!",
            message: "Payment sent, Please contact the admin to verify payment",
            type: "info",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        });
    } else {
      saveToDB(false);
      Store.addNotification({
        title: "Notification!",
        message: "Payment sent, Please contact the admin to verify payment",
        type: "info",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
  };

  const onUpGrade = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.paymentChannel === "Card" && formData.currency === "Naira") {
      initializePayment(onSuccess, onClose);
    } else {
      updateUserProfileData({
        registrationLevel: "Level 2",
        isProfileActive: true,
        isPaymentConfirmed: false,
        currency: formData.currency || "Bank Transfer",
      });
    }
  };

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const updateUserProfileData = async (updateData: Partial<IUserProfile>) => {
    setIsLoading(true);
    try {
      await updateProfile(user?.uid!, updateData);
      setIsLoading(false);
      getProfile(user?.uid!);
      Store.addNotification({
        title: "Notification!",
        message: "Account updated successfully",
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      if (isOpen) {
        setIsOpen(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.code) {
        Store.addNotification({
          title: "Notification!",
          message: error.code,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        Store.addNotification({
          title: "Notification!",
          message: "An error occurred. Please try again later",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateUserProfileData({
      fullName: formData.fullName,
      country: formData.country,
      phone: formData.phone,
      // email: formData.email,
    });
  };

  const onConfirm = () => {
    updateUserProfileData({
      registrationLevel: "Level 2",
      isProfileActive: true,
      isPaymentConfirmed: false,
      currency: formData.currency || "Bank Transfer",
    }).then(() => {
      setIsConfirm(false);
      navigate("/bank-transfer");
    });
  };

  return (
    <div>
      {profileData && profileData.isProfileActive ? (
        <>
          <div className='alert alert-info shadow-md mt-3'>
            <div>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='stroke-current flex-shrink-0 w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                ></path>
              </svg>
              <div className='flex flex-col items-start'>
                <span className='font-bold text-start'>Registration Level</span>
                <span className='text-start'>
                  <strong>{profileData.registrationLevel}:</strong>{" "}
                  {profileData.registrationLevel === "Level 1"
                    ? "Free - Access to public sessions & limited resources"
                    : "Access to private sessions & resources"}
                </span>
              </div>
            </div>
          </div>
          <form
            className='card flex-shrink-0 w-full shadow-md border mt-4'
            onSubmit={onSubmit}
          >
            <div className='card-body'>
              <h2 className='card-title'>Account details</h2>
              <div className='form-control'>
                <label className='label' htmlFor='name'>
                  <span className='label-text'>Full name</span>
                </label>
                <input
                  id='name'
                  type='text'
                  name='fullName'
                  placeholder='Full name'
                  className='input input-bordered input-info bg-white'
                  required
                  onChange={onChange}
                  value={formData.fullName}
                />
              </div>
              {/* <div className='form-control'>
                <label className='label' htmlFor='email'>
                  <span className='label-text'>Email</span>
                </label>
                <input
                  id='email'
                  type='email'
                  name='email'
                  placeholder='Email'
                  className='input input-bordered input-info bg-white'
                  required
                  onChange={onChange}
                  value={formData.email}
                />
              </div> */}
              <div className='form-control'>
                <label className='label' htmlFor='phone'>
                  <span className='label-text'>
                    Phone Number (with country code)
                  </span>
                </label>
                <input
                  id='phone'
                  type='phone'
                  name='phone'
                  placeholder='Phone Number'
                  className='input input-bordered input-info bg-white'
                  required
                  onChange={onChange}
                  value={formData.phone}
                />
              </div>

              <div className='form-control'>
                <label className='label' htmlFor='country'>
                  <span className='label-text'>Country</span>
                </label>
                <select
                  className='select select-bordered select-info bg-white'
                  id='country'
                  name='country'
                  required
                  onChange={onChange}
                  value={formData.country}
                >
                  <option disabled value=''>
                    Select country
                  </option>
                  {options.map((e) => (
                    <option key={e.value} value={e.label}>
                      {e.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className='card-actions sm:justify-between mt-4'>
                <button
                  className='btn btn-info btn-sm sm:w-auto w-full'
                  type='submit'
                  disabled={isLoading}
                >
                  Update Account
                </button>
                {profileData.registrationLevel === "Level 1" && (
                  <button
                    className='btn btn-info btn-sm sm:w-auto w-full btn-outline'
                    type='button'
                    onClick={() => setIsOpen(true)}
                    //   disabled={isLoading}
                  >
                    Upgrade Account
                  </button>
                )}
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          {isFetchingProfile ? (
            <div className='flex justify-center mt-10'>
              <progress className='progress w-56'></progress>
            </div>
          ) : (
            <div className='alert alert-warning shadow-lg'>
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='stroke-current flex-shrink-0 h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                  />
                </svg>
                <span>
                  Account is currently not active. Please contact admin
                </span>
              </div>
            </div>
          )}
        </>
      )}
      <div
        className={`modal modal-bottom sm:modal-middle ${
          isOpen && "modal-open"
        }`}
      >
        <div className='modal-box bg-white !text-gray-600'>
          <h3 className='font-bold text-lg'>Upgrade Account</h3>
          <form className='py-4' onSubmit={onUpGrade}>
            <div className='form-control'>
              <label className='label' htmlFor='channel'>
                <span className='label-text'>Payment channel</span>
              </label>
              <select
                className='select select-bordered select-info bg-white'
                id='channel'
                name='paymentChannel'
                required
                onChange={onChange}
                value={formData.paymentChannel}
              >
                <option disabled value=''>
                  Select payment channel
                </option>
                <option value='Card'>
                  Card Payment (Local and International){" "}
                </option>
                <option value='Bank'>Bank Transfer</option>
              </select>
            </div>
            {formData.paymentChannel === "Card" ? (
              <div className='form-control'>
                <label className='label' htmlFor='currency'>
                  <span className='label-text'>Currency</span>
                </label>
                <select
                  className='select select-bordered select-info bg-white'
                  id='currency'
                  name='currency'
                  required
                  onChange={onChange}
                  value={formData.currency}
                >
                  <option disabled value=''>
                    Select currency
                  </option>
                  <option value='Naira'>Naira (₦150,000)</option>
                  <option value='Dollar'>USD ($200)</option>
                  <option value='Pounds'>Pounds (£200)</option>
                  <option value='Euros'>Euro (€200)</option>
                </select>
              </div>
            ) : null}

            {formData.paymentChannel === "Bank" ||
            (formData.currency && formData.currency !== "Naira") ? (
              <p className='py-4'>
                Use any of the account details{" "}
                <span
                  className='text-sky-500 underline cursor-pointer'
                  onClick={() => setIsConfirm(true)}
                >
                  HERE
                </span>{" "}
                to make payment and send prove of payment to admin. You can
                login to your dashboard with your email and password.
              </p>
            ) : null}
            <div className='modal-action'>
              <button
                onClick={() => {
                  setIsOpen(false);
                  setFormData((prev) => ({
                    ...prev,
                    paymentChannel: "",
                    currency: "",
                  }));
                }}
                className='btn rounded-full btn-sm'
                type='button'
              >
                Cancel
              </button>
              <button
                type='submit'
                className='btn rounded-full btn-sm btn-info'
              >
                {formData.currency && formData.currency === "Naira"
                  ? "Make payment"
                  : "Upgrade account"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Modal
        isOpen={isConfirm}
        onClose={() => setIsConfirm(false)}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default AccountInfo;
