import React from "react";
import Hero from "../components/Hero";
import { ministryData } from "../constants/data";

const Ministries: React.FC = () => {
  return (
    <div className='h-screen'>
      <Hero />
      <div className='bg-white sm:pt-52 pt-20 pb-8 container sm:m-auto px-6 !text-gray-600'>
        <h1 className='sm:text-5xl text-3xl font-bold text-center mb-3 bg-transparent'>
          Ministries
        </h1>
        <div className='flex gap-5 flex-wrap justify-center'>
          {ministryData.map((min) => (
            <div
              className='card card-compact w-96 shadow-md border'
              key={min.title}
            >
              <figure>
                <img src={min.img} alt={min.title} loading='lazy' />
              </figure>
              <div className='card-body'>
                <h2 className='card-title'>{min.title}</h2>
                <p>{min.description}</p>
                <div className='card-actions justify-end'>
                  {min.link ? (
                    <a
                      href={min.link}
                      target='_blank'
                      rel='noreferrer'
                      className='btn btn-info btn-outline rounded-full btn-sm'
                    >
                      Read more
                    </a>
                  ) : // <button className='btn btn-info btn-outline rounded-full btn-sm'>
                  //   Read more
                  // </button>
                  null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ministries;
