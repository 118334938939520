import React from "react";
import { useLocation } from "react-router-dom";
import SocialLinks from "./SocialLinks";

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname === "/" ? (
        <div className='fixed bottom-0 z-50 flex justify-center'>
          <SocialLinks />
        </div>
      ) : null}
    </>
  );
};

export default Footer;
