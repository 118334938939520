import React from "react";
import Hero from "../components/Hero";
import pee from "../assets/P-2.png";
import { Link } from "react-router-dom";

const About: React.FC = () => {
  return (
    <div className='h-screen'>
      <Hero />
      <div className='hero bg-white sm:pt-52 pt-20 pb-8'>
        <div className='hero-content flex-col lg:flex-row'>
          <img
            src={pee}
            className='max-w-sm rounded-lg shadow-2xl w-full bg-sky-900 p-0'
            loading='lazy'
            alt='Bg'
          />
          <div className='text-gray-600 ml-9'>
            <h1 className='sm:text-5xl text-3xl font-bold '>About Dr David</h1>
            <p className='py-3'>
              Dr. David Ogbueli is a Minister of the Gospel, a Social Reformer,
              and an iconic transformational leader. He has great passion for
              Personal Transformation and National Transformation which has
              birthed various initiatives and ministries causing a positive
              change among the youth, governments, corporate institutions, the
              media and other stakeholders both in Africa and globally.
            </p>
            <p className='py-3'>
              He is a Management and Public Policy Consultant to private &
              public sector institutions and their leaders. He is a widely read
              author with over one hundred and fifty books published. A widely
              sought after clergyman, technocrat and teacher. He has a burning
              passion for human capital development as a key to nation building;
              his other interests are social reforms, transformational
              leadership, and nation transformation.
            </p>
            <p className='py-3'>
              He is the President of Golden Heart Foundation & National
              Development Centre set up to train & equip Nigerian & African
              youths on transformational leadership. He also seats on the board
              of Institute of National Transformation. He has been honoured by
              the United Nations, state governments, government agencies,
              corporate agencies & organisations for his youth development
              projects especially the annual National Youth Summit that empowers
              over 50,000 youths annually. He is a also member of National
              Institute for Public Policy & Strategic Studies, an alumnus of
              Lagos Business School, and IESE Business School in Spain. He seats
              on the board of six big organisations with a focus on development
              & transformation.
            </p>
            <p className='py-3'>
              His media broadcast reaches seven continents with increasing
              transformational influence on many lives, communities, cities &
              nations. He is married to Sarah Ogbueli and blessed with four
              children.
            </p>
            <Link to='/contact' className='btn btn-info btn-sm rounded-full'>
              Contact Me
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
