import React from "react";
import { IPlayListItem } from "../types";

interface VideoCardProps {
  videoData: IPlayListItem;
  handleClick: () => void;
}

const VideoCard: React.FC<VideoCardProps> = ({ videoData, handleClick }) => {
  const { snippet } = videoData;
  return (
    <div
      className='carousel-item card card-compact w-64 bg-white shadow-sm mr-5 border cursor-pointer'
      onClick={handleClick}
    >
      <figure>
        <img
          src={snippet?.thumbnails?.high?.url}
          alt={snippet.title}
          loading='lazy'
        />
      </figure>
      <div className='card-body'>
        {/* <h2 className='card-title'>{snippet.title.slice(0, 70) + " ..."}</h2> */}
        <p>{snippet.title.slice(0, 70) + " ..."}</p>
        {/* <div className='card-actions justify-end'>
          <button className='btn btn-info rounded-full'>Play</button>
        </div> */}
      </div>
    </div>
  );
};

export default VideoCard;
