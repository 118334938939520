import React from "react";
import Hero from "../components/Hero";
import gift from "../assets/gift-2.jpg";
import partner from "../assets/partner.jpg";
import donateP from "../assets/donate.png";
import { Link } from "react-router-dom";

const Give: React.FC = () => {
  return (
    <div className='h-screen'>
      <Hero />
      <div className='bg-white sm:pt-52 pt-20 pb-8 container sm:m-auto sm:px-6  !text-gray-600'>
        <h1 className='sm:text-5xl text-3xl font-bold text-center mb-3'>
          Give
        </h1>
        <div className='flex gap-5 flex-wrap justify-center'>
          <div className='card sm:w-96 w-72 shadow-md border card-compact'>
            <figure>
              <img
                src={gift}
                alt='Gift'
                loading='lazy'
                className='h-64 w-full'
              />
            </figure>
            <div className='card-body'>
              <h2 className='card-title'>Honour Dr David</h2>
              {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
              <div className='card-actions justify-between'>
                <a
                  href='https://www.dominioncity.cc/give/honour'
                  className='btn btn-info  btn-sm rounded-full sm:w-auto w-full'
                  target='_blank'
                  rel='noreferrer'
                >
                  Card Payment
                </a>

                <Link
                  to='/bank-transfer'
                  className='btn btn-info  btn-sm rounded-full sm:w-auto w-full'
                >
                  Bank Transfer
                </Link>
              </div>
            </div>
          </div>
          <div className='card sm:w-96 w-72 shadow-md border card-compact'>
            <figure>
              <img
                src={donateP}
                alt='Donation'
                loading='lazy'
                className='h-64 w-full'
              />
            </figure>
            <div className='card-body'>
              <h2 className='card-title'>Donations</h2>
              <div className='card-actions justify-end'>
                <a
                  href='https://www.dominioncity.cc/give/projects'
                  className='btn btn-info btn-sm rounded-full sm:w-auto w-full'
                  target='_blank'
                  rel='noreferrer'
                >
                  Donate
                </a>
              </div>
              {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
            </div>
          </div>
          <div className='card sm:w-96 w-72 shadow-md border card-compact'>
            <figure>
              <img src={partner} alt='Partnership' loading='lazy' />
            </figure>
            <div className='card-body'>
              <h2 className='card-title'>Partnership</h2>
              <div className='card-actions justify-end'>
                <a
                  href='https://www.dominioncity.cc/give/partnership'
                  className='btn btn-info btn-sm rounded-full sm:w-auto w-full'
                  target='_blank'
                  rel='noreferrer'
                >
                  Partner
                </a>
              </div>
              {/* <p>If a dog chews shoes whose shoes does he choose?</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Give;
