import React from "react";

const SocialLinks: React.FC = () => {
  return (
    <div className='p-3 flex rounded-full bg-slate-300 m-3 justify-center items-center'>
      <a
        className='flex bg-white text-red-500 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='https://www.youtube.com/c/pastordavidogbueli'
        target='_blank'
        rel='noreferrer'
      >
        <i className='fab fa-youtube'></i>
      </a>

      <a
        className='flex bg-white text-blue-600 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='https://web.facebook.com/pastordavidogbueli'
        target='_blank'
        rel='noreferrer'
      >
        <i className='fab fa-facebook-square'></i>
      </a>
      <a
        className=' flex bg-white text-blue-500 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='https://www.linkedin.com/in/drdavidogbueli/'
        target='_blank'
        rel='noreferrer'
      >
        <i className='fa-brands fa-linkedin'></i>
      </a>
      <a
        className=' flex bg-white text-blue-400 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='https://www.twitter.com/pstdavidogbueli'
        target='_blank'
        rel='noreferrer'
      >
        <i className='fab fa-twitter'></i>
      </a>
      <a
        className='flex bg-white text-red-500 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='https://www.instagram.com/pastordavidogbueli'
        target='_blank'
        rel='noreferrer'
      >
        <i className='fab fa-instagram'></i>
      </a>
      <a
        className='flex bg-white text-sky-500 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='http://t.me/dominioncityglobal'
        target='_blank'
        rel='noreferrer'
      >
        <i className='fa-brands fa-telegram'></i>
      </a>
      <a
        className='flex bg-white text-gray-500 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='https://www.tiktok.com/@pastordavidogbueli'
        target='_blank'
        rel='noreferrer'
      >
        <i className='fa-brands fa-tiktok'></i>
      </a>
      <a
        className='flex bg-white text-green-500 shadow-lg font-normal h-8 w-8 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
        href='https://api.whatsapp.com/send?phone=2348103526864'
      >
        <i className='fab fa-whatsapp'></i>
      </a>
    </div>
  );
};

export default SocialLinks;
