import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgL from "../assets/h-l.png";
import bgP from "../assets/h-p.png";
import bgP2 from "../assets/h-p2.png";
import bgL2 from "../assets/h-l2.png";
// import Typical from "react-typical";
import { useMediaQuery } from "react-responsive";

const Homepage: React.FC = () => {
  const settings: Settings = {
    // dots: true,
    infinite: true,
    // speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    // autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
    lazyLoad: "progressive",
  };

  // const steps = [
  //   "is a revivalist",
  //   4000,
  //   "a life coach",
  //   4000,
  //   "a social worker",
  //   4000,
  //   "is a human capital development expert",
  //   4000,
  //   "is the founder, Women Safe House Initiative and Cuddlewithjayne Foundation",
  //   4000,
  // ];

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 640px)",
  });

  return (
    <div className='w-full sm:h-screen overflow-hidden'>
      {/* <div
        className='hero min-h-screen'
        style={{
          backgroundImage: `url("https://placeimg.com/1000/800/arch")`,
        }}
      >
        <div className='hero-overlay bg-opacity-60'></div>
        <div className='hero-content text-center text-neutral-content'>
          <div className='max-w-md'>
            <h1 className='mb-5 text-5xl font-bold'>Hello there</h1>
            <p className='mb-5'>
              Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
              excepturi exercitationem quasi. In deleniti eaque aut repudiandae
              et a id nisi.
            </p>
            <button className='btn btn-primary'>Get Started</button>
          </div>
        </div>
      </div> */}
      <Slider {...settings}>
        <div className='h-full w-full'>
          <img
            src={isDesktopOrLaptop ? bgL : bgP}
            alt={"Text"}
            className='sm:object-cover object-contain h-screen w-full'
            loading='lazy'
          />
        </div>
        <div className='h-full w-full'>
          <img
            src={isDesktopOrLaptop ? bgL2 : bgP2}
            alt={"Text"}
            className='sm:object-cover object-contain h-screen w-full'
            loading='lazy'
          />
        </div>
      </Slider>
      {/* <div className='carousel w-full'>
        <div id='slide1' className='carousel-item relative w-full'>
          <img src={isDesktopOrLaptop ? bgL : bgP} className='w-full' />
          <div className='absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2'>
            <a href='#slide4' className='btn btn-circle btn-primary'>
              ❮
            </a>
            <a href='#slide2' className='btn btn-circle btn-primary'>
              ❯
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Homepage;
