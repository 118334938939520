import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";

import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  const { pathname } = useLocation();
  return (
    <div
      className={`relative sm:h-screen h-full ${
        pathname === "/" ? "bg-slate-200" : "bg-white"
      }`}
    >
      <ReactNotifications />
      <Header />
      {/* <div className={pathname !== '/' ? 'sm:py-0 py-12 bg-white' : ''}>
        <Outlet />
      </div> */}
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
