import React, { useState, useRef } from "react";
import { IResourceMedia } from "../types";
// import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";
import ReactJkMusicPlayer, {
  ReactJkMusicPlayerAudioInfo,
  ReactJkMusicPlayerInstance,
} from "react-jinke-music-player";
import { Player, BigPlayButton } from "video-react";

// flex items-center justify-center fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2

const Modal = ({
  isOpen,
  videoData,
  onClose,
}: {
  isOpen: boolean;
  videoData: { url: string; poster: string };
  onClose: () => void;
}) => {
  //   const isDesktopOrLaptop = useMediaQuery({
  //     query: "(min-width: 640px)",
  //   });
  return (
    <div className={isOpen ? "modal modal-open" : "modal"}>
      <div className='modal-box !w-full !h-screen overflow-hidden bg-gray-900'>
        <label
          htmlFor='my-modal-3'
          className='btn btn-sm btn-circle absolute right-2 top-2'
          onClick={onClose}
        >
          ✕
        </label>
        <div className='flex items-center justify-center flex-col mt-32'>
          {/* <ReactPlayer
            url={url}
            //     controls
            width={isDesktopOrLaptop ? 500 : 400}
            height={isDesktopOrLaptop ? 390 : 230}
            playing
          /> */}
          <Player playsInline poster={videoData.poster} src={videoData.url}>
            <BigPlayButton position='center' />
          </Player>
        </div>
      </div>
    </div>
  );
};

interface ResourceMediaProps {
  list: IResourceMedia[];
  title: string;
  type: "video" | "audio";
}

// const audioList1 = [
//   {
//     name: "Despacito",
//     singer: "Luis Fonsi",
//     cover:
//       "http://res.cloudinary.com/alick/image/upload/v1502689731/Despacito_uvolhp.jpg",
//     musicSrc:
//       "http://res.cloudinary.com/alick/video/upload/v1502689683/Luis_Fonsi_-_Despacito_ft._Daddy_Yankee_uyvqw9.mp3",
//     // support async fetch music src. eg.
//     // musicSrc: async () => {
//     //   return await fetch('/api')
//     // },
//   },
//   {
//     name: "Dorost Nemisham",
//     singer: "Sirvan Khosravi",
//     cover:
//       "https://res.cloudinary.com/ehsanahmadi/image/upload/v1573758778/Sirvan-Khosravi-Dorost-Nemisham_glicks.jpg",
//     musicSrc:
//       "https://res.cloudinary.com/ehsanahmadi/video/upload/v1573550770/Sirvan-Khosravi-Dorost-Nemisham-128_kb8urq.mp3",
//   },
// ];

const ResourceMedia: React.FC<ResourceMediaProps> = ({ list, title, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoData, setVideoData] = useState<{
    url: string;
    poster: string;
  } | null>(null);
  const [playingAudio, setPlayingAudio] =
    useState<ReactJkMusicPlayerAudioInfo | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef<ReactJkMusicPlayerInstance | null>(null);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 640px)",
  });

  return (
    <div>
      <h1 className='mt-9 sm:text-5xl text-3xl font-bold text-center mb-3 bg-transparent text-gray-600'>
        {title}
      </h1>
      <div className='sm:p-0 p-3 flex flex-wrap justify-center gap-4'>
        {list.length ? (
          <>
            {list.map((e, index) => (
              <div
                className={`card shadow-md card-compact w-64 cursor-pointer  bg-gray-900 ${
                  playingAudio?.name === e.title
                    ? "border-info border-2"
                    : "border"
                }`}
                key={e._id}
              >
                <figure>
                  <img
                    src={e.artwork}
                    alt={e.title}
                    loading='lazy'
                    className='h-[163px]'
                  />
                </figure>
                <div className='card-body bg-gray-200 text-gray-600'>
                  <h2 className='card-title'>{e.artist}</h2>
                  <p>{e.title.slice(0, 70) + " ..."}</p>
                  <div className='card-actions justify-end'>
                    <button
                      className='btn btn-info btn-circle'
                      onClick={() => {
                        if (type === "audio") {
                          if (playingAudio) {
                            audioRef.current?.togglePlay &&
                              audioRef.current.togglePlay();
                          } else {
                            if (list.length === 1) {
                              audioRef.current?.play && audioRef.current.play();
                            } else {
                              audioRef.current?.playByIndex &&
                                audioRef.current.playByIndex(index);
                            }

                            // audioRef.current?.playByIndex &&
                            //   audioRef.current.playByIndex(index);
                            // console.log("here pp", index);
                          }
                        } else {
                          setVideoData({
                            url: e.linkUrl || e.fileUrl,
                            poster: e.artwork,
                          });
                          setIsOpen(true);
                        }
                      }}
                    >
                      {playingAudio?.name === e.title && isPlaying ? (
                        <i className='fa-solid fa-pause'></i>
                      ) : (
                        <i className='fa-solid fa-play'></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div
            className='flex items-center justify-center fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2 
           z-40'
          >
            <div className='alert alert-info shadow-lg'>
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  className='stroke-current flex-shrink-0 w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                  ></path>
                </svg>
                <span>No file available. Please check back later</span>
              </div>
            </div>
          </div>
        )}
      </div>
      {type === "audio" && list.length ? (
        <ReactJkMusicPlayer
          getAudioInstance={(instance) => {
            audioRef.current = instance;
          }}
          showDownload={false}
          showThemeSwitch={false}
          // theme={isDesktopOrLaptop ? "dark" : "light"}
          // responsive={false}
          mobileMediaQuery='(max-width: 1024px)'
          glassBg
          mode={isDesktopOrLaptop ? "mini" : "mini"}
          remove={false}
          defaultPosition={{
            right: 10,
            bottom: 100,
          }}
          autoPlay={false}
          onAudioPlay={(audioInfo) => {
            //     console.log({ audioInfo });
            setPlayingAudio(audioInfo);
            setIsPlaying(true);
          }}
          onAudioPause={() => {
            setIsPlaying(false);
          }}
          audioLists={list.map((e) => ({
            singer: e.artist,
            name: e.title,
            musicSrc: e.linkUrl || e.fileUrl,
            cover: e.artwork,
          }))}
        />
      ) : null}

      {videoData && (
        <Modal
          isOpen={isOpen}
          videoData={videoData}
          onClose={() => {
            setVideoData(null);
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ResourceMedia;
