import React from "react";
import Hero from "../components/Hero";

const GMTV: React.FC = () => {
  return (
    <div className='h-screen bg-gray-900'>
      <Hero />
      <div className='sm:pt-52 pt-20 pb-8 container sm:m-auto sm:px-6  !text-gray-300 bg-gray-900'>
        <h1 className='sm:text-5xl text-3xl font-bold text-center mb-3'>
          Global Missions TV (GMTV)
        </h1>
        <div className='flex justify-center mt-5'>
          <iframe
            title='GMTV'
            src='https://iframe.viewmedia.tv?channel=160'
            width='640'
            height='360'
            frameBorder='0'
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GMTV;
