import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountInfo from "../components/AccountInfo";
import Hero from "../components/Hero";
import Resources from "../components/Resources";
import Session from "../components/Session";
import { useGlobalState } from "../context/GlobalStore";
import { logout } from "../firebase/firebase.config";

const Profile: React.FC = () => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  const { user, isFetchingUser, profileData, isFetchingProfile } =
    useGlobalState();

  useEffect(() => {
    if (!user && !isFetchingUser) {
      navigate("/login");
    }
  }, [user, navigate, isFetchingUser]);

  useEffect(() => {
    if (location.search && location.search === "?tab=2") {
      setActiveTab(2);
    }
  }, [location]);

  return (
    <div className='h-screen'>
      <Hero />
      <div className='bg-white sm:pt-[210px] pt-20 pb-8 text-gray-600 px-4'>
        <div className='flex justify-center mb-2'>
          <h1 className='sm:text-5xl text-3xl font-bold text-center'>
            Dashboard
          </h1>
        </div>
        {isFetchingUser || isFetchingProfile ? (
          <div className='flex justify-center mt-10'>
            <progress className='progress progress-info w-56'></progress>
          </div>
        ) : (
          <>
            {profileData && profileData.isProfileActive ? (
              <>
                <div className='flex justify-center mb-5'>
                  <button
                    className='btn btn-sm btn-error'
                    onClick={async () => {
                      try {
                        await logout();
                        navigate("/");
                      } catch (error) {}
                    }}
                  >
                    Logout
                  </button>
                </div>
                <div className='flex justify-center sm:w-1/2 flex-col gap-4 w-full sm:m-auto container'>
                  <div className='inline-flex border-b border-gray-200 self-center'>
                    {/* <button className='h-10 px-4 py-2 -mb-px text-sm text-center text-blue-600 bg-transparent border-b-2 border-blue-500 sm:text-base whitespace-nowrap focus:outline-none'>
              Profile
            </button> */}

                    <button
                      className={`h-10 px-4 py-2 -mb-px text-sm text-center text-blue-600 bg-transparent border-b-2  sm:text-base whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400 ${
                        activeTab === 1
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-700 "
                      }`}
                      onClick={() => setActiveTab(1)}
                    >
                      Account
                    </button>

                    <button
                      className={`h-10 px-4 py-2 -mb-px text-sm text-center text-blue-600 bg-transparent border-b-2  sm:text-base whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400 ${
                        activeTab === 2
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-700 "
                      }`}
                      onClick={() => setActiveTab(2)}
                    >
                      Broadcast
                    </button>
                    <button
                      className={`h-10 px-4 py-2 -mb-px text-sm text-center text-blue-600 bg-transparent border-b-2  sm:text-base whitespace-nowrap cursor-base focus:outline-none hover:border-gray-400 ${
                        activeTab === 3
                          ? "border-blue-500 text-blue-600"
                          : "border-transparent text-gray-700 "
                      }`}
                      onClick={() => setActiveTab(3)}
                    >
                      Resources
                    </button>
                  </div>
                  {activeTab === 1 && <AccountInfo />}
                  {activeTab === 2 && <Session />}
                  {activeTab === 3 && <Resources />}
                </div>
              </>
            ) : (
              <div className='flex justify-center pt-6 sm:w-1/2 flex-col gap-4 w-full sm:m-auto container'>
                <div className='alert alert-warning shadow-lg'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='stroke-current flex-shrink-0 h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
                      />
                    </svg>
                    <span>
                      Account is currently not active. Please contact admin
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
