import React from "react";
import Hero from "../components/Hero";

const BankTransfer: React.FC = () => {
  return (
    <div className='h-screen'>
      <Hero />
      <div className='bg-white sm:pt-52 pt-20 pb-8 container sm:m-auto sm:px-6  !text-gray-600'>
        <h1 className='sm:text-5xl text-3xl font-bold text-center mb-3'>
          Account Details
        </h1>
        <div className='flex gap-5 flex-wrap sm:justify-start justify-center mt-5'>
          <div className='card flex-shrink-0 sm:w-96 w-72 shadow-md border'>
            <div className='card-body'>
              <h2 className='card-title'> NAIRA</h2>
              <p>
                DAVID OGBUELI MINISTRIES <br />
                <strong>Beneficiary bank:</strong> Zenith Bank Nigeria <br />
                <strong>Account Number:</strong> 1012549668 <br />
                <strong>SORT CODE:</strong> 057150835 <br />{" "}
                <strong>SWIFT CODE:</strong> ZEIBNGLA
              </p>
            </div>
          </div>
          <div className='card flex-shrink-0 sm:w-96 w-72 shadow-md border'>
            <div className='card-body'>
              <h2 className='card-title'>USD</h2>
              <p>
                DAVID OGBUELI MINISTRIES <br />
                <strong>Beneficiary bank:</strong> Zenith Bank Nigeria <br />
                <strong>Account Number:</strong> 5073514281 <br />
                <strong>Beneficiary bank SWIFT CODE:</strong> ZEIBNGLA <br />
                <strong>Intermediary bank:</strong>
                <br />
                CITIBANK N.A. <br />
                111 WALL STREET, <br />
                NEW YORK <br />
                NEW YORK 10043 <br />
                <strong>Intermediary Bank Swift Address:</strong> CITIUS33{" "}
                <br />
                <strong>Intermediary Bank IBAN number:</strong> 021000089 <br />
                <strong>Intermediary Bank Account no:</strong> 36204566 (USD)
              </p>
            </div>
          </div>
          <div className='card flex-shrink-0 sm:w-96 w-72 shadow-md border'>
            <div className='card-body'>
              <h2 className='card-title'>POUNDS</h2>
              <p>
                DAVID OGBUELI MINISTRIES <br />
                <strong>Beneficiary bank:</strong> Zenith Bank Nigeria <br />
                <strong>Account Number:</strong> 5061142883 <br />
                <strong>Beneficiary bank SWIFT CODE:</strong> ZEIBNGLA <br />
                <strong>Intermediary bank:</strong>
                <br />
                CITIBANK N.A. <br />
                Canada Square, <br />
                Canary Wharf <br />
                London. <br />
                E14 5LB <br />
                <strong>Intermediary Bank SORT CODE:</strong> 18-50-08 <br />
                <strong>Intermediary Bank Swift Address:</strong> CITIGB2L
                <br />
                <strong>Intermediary Bank IBAN number:</strong>{" "}
                GB22CITI18500805527945 <br />
                <strong>Intermediary Bank Account no:</strong> 5527945 (GBP)
              </p>
            </div>
          </div>
          <div className='card flex-shrink-0 sm:w-96 w-72 shadow-md border'>
            <div className='card-body'>
              <h2 className='card-title'>EURO</h2>
              <p>
                DAVID OGBUELI MINISTRIES <br />
                <strong>Beneficiary bank:</strong> Zenith Bank Nigeria <br />
                <strong>Account Number:</strong> 5080921010 <br />
                <strong>Beneficiary bank SWIFT CODE:</strong> ZEIBNGLA <br />
                <strong>Intermediary bank:</strong>
                <br />
                CITIBANK Europe Plc <br />
                1 North Wall, Quay, <br />
                Dublin, Ireland <br />
                <strong>Intermediary Bank SORT CODE:</strong> 18-50-08 <br />
                <strong>Intermediary Bank Swift Address:</strong> CITIIE2X
                <br />
                <strong>Intermediary Bank IBAN number:</strong>{" "}
                GB87CITI18500808663076 <br />
                <strong>Intermediary Bank Account no:</strong> 8663076 (EURO)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTransfer;
