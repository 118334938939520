import React, { useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGlobalState } from "../context/GlobalStore";
import { logout } from "../firebase/firebase.config";
import useOnClickOutside from "../hooks/useOnClickOutside";

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useGlobalState();

  const ref = useRef<any>(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const links = [
    "/public-resource",
    "/private-resource",
    "/public-stream",
    "/private-stream",
    "/player",
  ];

  return (
    <div
      className={`navbar min-h-0 fixed z-50  ${
        links.includes(pathname) && "bg-white"
      }`}
    >
      {links.includes(pathname) && (
        <div className='flex-1 px-2 lg:flex-none'>
          <Link
            to={
              pathname.includes("stream") || pathname.includes("resource")
                ? "/dashboard"
                : "/vod"
            }
            className='btn btn-sm'
            //   className={`text-lg font-bold  ${
            //     pathname === "/" ? "text-white " : "text-gray-600"
            //   }`}
          >
            <i className='fa-solid fa-circle-arrow-left mr-2'></i>
            Back
          </Link>
        </div>
      )}
      <div className='flex justify-end flex-1 px-2'>
        <div className='flex items-stretch'>
          <div
            className={`dropdown dropdown-end drop-shadow-sm ${
              isOpen && "dropdown-open"
            }`}
            ref={ref}
          >
            <label
              tabIndex={0}
              className='btn btn-info sm:btn-md btn-sm rounded-full'
              onClick={() => setIsOpen((prev) => !prev)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                className='inline-block w-5 h-5 stroke-current'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
              <span className='ml-2'> Menu</span>
            </label>
            <ul
              tabIndex={0}
              className={`menu dropdown-content p-2 shadow rounded-box w-52 mt-4 bg-white text-gray-600 border border-info ${
                isOpen ? "!visible" : "!invisible"
              }`}
            >
              <li
                className={pathname === "/" ? "bg-info text-white" : ""}
                onClick={() => setIsOpen(false)}
              >
                <Link to='/'>Home</Link>
              </li>
              <li
                className={
                  pathname === "/about" ? "bg-info rounded-md text-white" : ""
                }
                onClick={() => setIsOpen(false)}
              >
                <Link to='/about'>About Dr David</Link>
              </li>
              <li
                className={
                  pathname === "/give" ? "bg-info rounded-md text-white" : ""
                }
                onClick={() => setIsOpen(false)}
              >
                <Link to='/give'>Give</Link>
              </li>
              <li
                className={
                  pathname === "/mentorship"
                    ? "bg-info rounded-md text-white"
                    : ""
                }
                onClick={() => setIsOpen(false)}
              >
                <Link to='/mentorship'>Mentorship</Link>
              </li>
              <li
                className={
                  pathname === "/media" ? "bg-info rounded-md text-white" : ""
                }
                onClick={() => setIsOpen(false)}
              >
                <Link to='/media'>Media Center</Link>
              </li>
              <li
                className={
                  pathname === "/ministries"
                    ? "bg-info rounded-md text-white"
                    : ""
                }
                onClick={() => setIsOpen(false)}
              >
                <Link to='/ministries'>Ministries</Link>
              </li>
              <li
                className={
                  pathname === "/contact"
                    ? "bg-info rounded-md  text-white"
                    : ""
                }
                onClick={() => setIsOpen(false)}
              >
                <Link to='/contact'>Contact Dr David</Link>
              </li>
              {user && (
                <li
                  className={
                    pathname === "/dashboard"
                      ? "bg-info rounded-md  text-white"
                      : ""
                  }
                  onClick={() => setIsOpen(false)}
                >
                  <Link to='/dashboard'>Dashboard</Link>
                </li>
              )}
              {!user && (
                <li
                  className={pathname === "/login" ? "bg-info  text-white" : ""}
                  onClick={() => setIsOpen(false)}
                >
                  <Link to='/login'>Login</Link>
                </li>
              )}
              {user && (
                <button
                  className='btn mt-4 btn-error'
                  onClick={async () => {
                    try {
                      await logout();
                      navigate("/");
                      setIsOpen(false);
                    } catch (error) {}
                  }}
                >
                  Logout
                </button>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
