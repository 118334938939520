import React, { useEffect } from "react";
// import YouTube from "react-youtube";
import { useMediaQuery } from "react-responsive";
import { useGlobalState } from "../context/GlobalStore";
import { useNavigate } from "react-router-dom";

const PublicStream: React.FC = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 640px)",
  });
  //   const opts = {
  //     height: isDesktopOrLaptop ? "390" : "230",
  //     width: isDesktopOrLaptop ? "640" : "370",
  //     playerVars: {
  //       // https://developers.google.com/youtube/player_parameters
  //       autoplay: 1,
  //       rel: 0,
  //       showinfo: 0,
  //       ecver: 2,
  //       modestbranding: 1,
  //       controls: 0,
  //     },
  //   };

  const navigate = useNavigate();
  const { user, isFetchingUser } = useGlobalState();

  useEffect(() => {
    if (!user && !isFetchingUser) {
      navigate("/login");
    }
  }, [user, navigate, isFetchingUser]);

  return (
    <div className='h-screen bg-gray-900'>
      <div className='flex items-center justify-center fixed left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-1/2 bg-gray-900 z-40'>
        {isFetchingUser ? (
          <progress className='progress progress-info w-56'></progress>
        ) : (
          <iframe
            id='r-embed-player-iframe'
            src={`https://player.restream.io/?token=a1646c2489a94f51b4cf43d6ddfa9de0&vwrs=1`}
            allow='autoplay'
            allowFullScreen
            className='overflow-hidden'
            title='LiveStream'
            height={isDesktopOrLaptop ? "390" : "230"}
            width={isDesktopOrLaptop ? "640" : "370"}
          ></iframe>

          //   <YouTube videoId={"3QUq3hXiNv0"} opts={opts} />
          // <div className='alert alert-info shadow-lg'>
          //   <div>
          //     <svg
          //       xmlns='http://www.w3.org/2000/svg'
          //       fill='none'
          //       viewBox='0 0 24 24'
          //       className='stroke-current flex-shrink-0 w-6 h-6'
          //     >
          //       <path
          //         strokeLinecap='round'
          //         strokeLinejoin='round'
          //         strokeWidth='2'
          //         d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
          //       ></path>
          //     </svg>
          //     <span>No broadcast currently. Please check back later</span>
          //   </div>
          // </div>
        )}
      </div>
    </div>
  );
};

export default PublicStream;
