import React, { useState, useRef } from "react";
import Player from "../components/Player";
import { useGlobalState } from "../context/GlobalStore";
import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { IPlayListItem } from "../types";

// container sm:m-auto

const VideoPlayer: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const playerRef = useRef<YouTubePlayer>(null);

  const { playingItem, setPlayingItem } = useGlobalState();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 640px)",
  });

  const opts = {
    height: isDesktopOrLaptop ? "390" : "230",
    width: isDesktopOrLaptop ? "640" : "370",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0,
      showinfo: 0,
      ecver: 2,
      modestbranding: 1,
    },
  };

  const _onReady = (event: YouTubeEvent<any>) => {
    // access to player in all event handlers via event.target
    playerRef.current = event.target;
  };

  const onPlayPausePressed = (id: string) => {
    if (playerRef.current && playingItem) {
      if (isPlaying && playingItem.videoData.id === id) {
        playerRef.current.pauseVideo();
        // setIsPlaying(false);
      } else {
        playerRef.current.playVideo();
        // setIsPlaying(true);
      }
    }
  };

  const loadVideo = (video: IPlayListItem) => {
    if (playingItem) {
      setPlayingItem({ ...playingItem, videoData: video });
    }
  };

  const playNext = (id: string) => {
    if (playingItem) {
      const playlistItems = playingItem.items;
      if (playlistItems.length === 1) {
        playerRef.current.stopVideo();
        setIsPlaying(false);
      } else {
        const index = playlistItems.findIndex((e) => e.id === id);
        if (index !== -1) {
          if (index === playlistItems.length - 1) {
            const newPlayingItem = playlistItems[0];
            setPlayingItem({ ...playingItem, videoData: newPlayingItem });
          } else {
            const newPlayingItem = playlistItems[index + 1];
            setPlayingItem({ ...playingItem, videoData: newPlayingItem });
          }
        }
      }
    }
  };

  return (
    <div className='h-screen bg-gray-900'>
      {playingItem && (
        <div className='flex items-center justify-center fixed right-0 left-0 sm:top-16 top-12 bg-gray-900 z-40'>
          <YouTube
            onReady={_onReady}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            videoId={
              playingItem.videoData.snippet.resourceId.videoId ||
              playingItem.videoData.id
            }
            opts={opts}
            onEnd={() => playNext(playingItem.videoData.id)}
          />
        </div>
      )}

      {playingItem ? (
        <div className='pb-8 px-6 !text-gray-300 sm:pt-[450px] pt-64 bg-gray-900'>
          <Player
            playlistItems={playingItem.items}
            playingId={playingItem.videoData.id}
            isPlaying={isPlaying}
            onPlayPausePressed={onPlayPausePressed}
            loadVideo={loadVideo}
          />
        </div>
      ) : (
        <div className='pb-8 flex justify-center flex-col items-center px-6 !text-gray-300 sm:pt-[150px] pt-64 bg-gray-900'>
          <h5>No Playlist selected. Go back to Playlist</h5>
          <Link
            to='/vod'
            className='btn mt-4 btn-sm'
            //   className={`text-lg font-bold  ${
            //     pathname === "/" ? "text-white " : "text-gray-600"
            //   }`}
          >
            <i className='fa-solid fa-circle-arrow-left mr-2 '></i>
            Back
          </Link>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
