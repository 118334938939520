import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Hero from "../components/Hero";
import heroBg from "../assets/bg-2.jpg";
import { loginUser } from "../firebase/firebase.config";
import { Store } from "react-notifications-component";

const Login: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, name } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await loginUser(formData.email, formData.password);
      navigate("/dashboard");
    } catch (error: any) {
      if (error.code) {
        Store.addNotification({
          title: "Notification!",
          message: error.code,
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      } else {
        Store.addNotification({
          title: "Notification!",
          message: "An error occurred. Please try again later",
          type: "danger",
          insert: "top",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
    }
  };

  return (
    <div
      className='hero min-h-screen'
      style={{ backgroundImage: `url(${heroBg})` }}
    >
      <div className='hero-overlay bg-opacity-60'></div>
      <div className='hero-content text-center text-neutral-content'>
        <div className='max-w-md'>
          <h1 className='mb-5 sm:text-5xl text-3xl font-bold'>Welcome Back!</h1>
          <p className='mb-5'>Login to your mentorship dashboard</p>
          {/* <Link to='/' className='btn btn-info rounded-full'>
            Back to home
          </Link> */}
          <div className='card  w-full  shadow-2xl bg-white text-gray-900'>
            <form className='card-body' onSubmit={onSubmit}>
              <div className='form-control'>
                <label className='label' htmlFor='email'>
                  <span className='label-text'>Email</span>
                </label>
                <input
                  id='email'
                  type='email'
                  placeholder='email'
                  name='email'
                  className='input input-bordered input-info bg-white'
                  value={formData.email}
                  onChange={onChange}
                  required
                />
              </div>
              <div className='form-control'>
                <label className='label' htmlFor='password'>
                  <span className='label-text'>Password</span>
                </label>
                <input
                  id='password'
                  type='password'
                  name='password'
                  placeholder='password'
                  className='input input-bordered input-info bg-white'
                  value={formData.password}
                  onChange={onChange}
                  required
                />
                {/* <label className='label'>
                  <a href='#' className='label-text-alt link link-hover'>
                    Forgot password?
                  </a>
                </label> */}
              </div>
              <div className='form-control mt-6'>
                <button className='btn btn-info rounded-full' type='submit'>
                  Login
                </button>
              </div>
              <small>Don't have an account</small>
              <div className='form-control mt-6'>
                <Link
                  to='/mentorship'
                  className='btn btn-info rounded-full btn-outline'
                >
                  Register
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
