import React from "react";
import { Link } from "react-router-dom";
import Hero from "../components/Hero";
import SocialLinks from "../components/SocialLinks";
import { useGlobalState } from "../context/GlobalStore";

const Mentorship: React.FC = () => {
  const { user } = useGlobalState();

  return (
    <div className='h-screen'>
      <Hero />
      <div className='hero bg-white sm:pt-52 pt-20 pb-8 text-gray-600'>
        <div className='hero-content text-center'>
          <div className='min-w-lg'>
            <h1 className='sm:text-5xl text-3xl font-bold'>
              Global Leadership Forum
            </h1>
            <p className='py-6'>
              A mentorship & training platform established for human capital
              development & transformational leadership for exponential growth
              in performance & productivity in any field of endeavour -
              ministry, career, enterprise, politics, government etc.
            </p>
            {user ? (
              <Link
                to='/dashboard'
                // href='https://docs.google.com/forms/d/e/1FAIpQLSfgeec-33xtMrVnCvuUk91VKzV6L_I__C56g-ImCMGn8YOnRQ/viewform'
                // target='_blank'
                // rel='noreferrer'
                className='btn btn-info btn-sm mt-5 rounded-full'
              >
                Go to account dashboard
              </Link>
            ) : (
              <>
                <h4 className='font-bold text-2xl mb-7'>
                  Join the mentorship program today
                </h4>
                <div className='flex flex-col items-center'>
                  <span className='btn btn-circle btn-sm btn-info'>1</span>
                  <p>
                    Subscribe & follow Dr David Ogbueli on socials for quick
                    access to resources & event updates.
                  </p>
                  <SocialLinks />
                </div>
                <div className='mt-5  mb-7'>
                  <span className='btn btn-circle btn-sm btn-info'>2</span>
                  <p>
                    Fill the{" "}
                    <Link
                      // href='https://docs.google.com/forms/d/e/1FAIpQLSfgeec-33xtMrVnCvuUk91VKzV6L_I__C56g-ImCMGn8YOnRQ/viewform'
                      // target='_blank'
                      // rel='noopener noreferrer'
                      to='/register'
                      className='text-sky-500 underline'
                    >
                      registration form
                    </Link>{" "}
                    and select the level of membership of your choice.
                  </p>
                </div>
                <h6 className='font-bold text-xl'>For more information</h6>
                <div className='flex flex-col'>
                  <a href='mailto:mentorship@davidogbueli.org'>
                    mentorship@davidogbueli.org
                  </a>
                  <a href='tel:+2347060574969'>+234-7060574969</a>
                  <a href='tel:+2348038246281'>+234-8038246281</a>
                </div>

                <div className='flex sm:flex-row sm:justify-center flex-col gap-4 '>
                  <Link
                    to='/register'
                    // href='https://docs.google.com/forms/d/e/1FAIpQLSfgeec-33xtMrVnCvuUk91VKzV6L_I__C56g-ImCMGn8YOnRQ/viewform'
                    // target='_blank'
                    // rel='noreferrer'
                    className='btn btn-info btn-sm mt-5 rounded-full'
                  >
                    Register
                  </Link>

                  <Link
                    to='/login'
                    // href='https://docs.google.com/forms/d/e/1FAIpQLSfgeec-33xtMrVnCvuUk91VKzV6L_I__C56g-ImCMGn8YOnRQ/viewform'
                    // target='_blank'
                    // rel='noreferrer'
                    className='btn btn-info btn-outline btn-sm sm:mt-5 rounded-full'
                  >
                    Log into account
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentorship;
